import React, { useCallback } from "react";
import Webcam from "react-webcam";
import styled from "styled-components";
import { colors } from "../values";
import TakePhotoImage from "../assets/takephoto.png";
import { formatDate, formatHeading } from "../utils";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";

const videoConstraints = {
  // width: 250,
  // height: 250,
  facingMode: "environment",
  // aspectRatio: 1,
};

export const WebcamCapture = ({
  image,
  setImage,
  settings,
  geolocation,
  onTake,
}) => {
  const [showCanvas, setShowCanvas] = useState(false);

  const webcamRef = useRef(null);
  const canvasRef = useRef();

  const capture = useCallback(() => {
    const dataUri = webcamRef.current.getScreenshot();

    const timestamp = settings.addTimestamp ? formatDate(new Date()) : "";
    let location = "";
    let latitude = 0;
    let longitude = 0;
    if (settings.locationServices && !geolocation.error) {
      latitude = Number(geolocation.latitude);
      longitude = Number(geolocation.longitude);
      location = `${latitude.toFixed(4)}, ${longitude.toFixed(4)}`;
    }

    const heading =
      settings.addHeading && !geolocation.error && geolocation.heading !== null
        ? formatHeading(geolocation.heading)
        : "";

    const img = new Image();
    img.onload = function () {
      const { width, height } = img;
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      const ctx = canvasRef.current.getContext("2d");
      ctx.drawImage(img, 0, 0);

      const infoList = [timestamp, location, heading].filter(Boolean);

      if (infoList.length === 0) {
        setShowCanvas(true);
        return;
      }

      let textWidth = 136;
      let textHeight = 5;
      let positions = [];
      infoList.forEach((info) => {
        textHeight += 19;
        positions.push(textHeight - 12);
      });

      ctx.fillStyle = "#ffffffaa";
      ctx.fillRect(width - textWidth, 0, textWidth, textHeight);
      ctx.textAlign = "right";
      ctx.fillStyle = colors.black;
      ctx.textBaseline = "middle";
      ctx.font = "12px monospace";

      infoList.forEach((info, i) => {
        ctx.fillText(info, width - 5, positions[i]);
      });

      onTake(canvasRef.current.toDataURL(), latitude, longitude);
      setImage(canvasRef.current.toDataURL());
      setShowCanvas(true);
    };
    img.src = dataUri;
  }, [
    settings.addTimestamp,
    settings.locationServices,
    settings.addHeading,
    geolocation.error,
    geolocation.latitude,
    geolocation.longitude,
    geolocation.heading,
    onTake,
    setImage,
  ]);

  useEffect(() => {
    if (!image) {
      setShowCanvas(false);
      return;
    }
  }, [image]);

  return (
    <Wrapper>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/png"
        width="100%"
        videoConstraints={videoConstraints}
        style={{ display: showCanvas ? "none" : "block" }}
      />
      <canvas
        ref={canvasRef}
        style={{ display: showCanvas ? "block" : "none" }}
      />
      {!showCanvas && (
        <>
          {(settings.addTimestamp ||
            (settings.locationServices && !geolocation.error)) && (
            <InfoWrapper>
              {settings.addTimestamp && <p>{formatDate(new Date())}</p>}
              {settings.locationServices && !geolocation.error && (
                <p>
                  {Number(geolocation.latitude).toFixed(4)},{" "}
                  {Number(geolocation.longitude).toFixed(4)}
                </p>
              )}
              {settings.addHeading &&
                !geolocation.error &&
                geolocation.heading !== null && (
                  <p>{formatHeading(geolocation.heading)}</p>
                )}
            </InfoWrapper>
          )}
          <CaptureButton
            onClick={(e) => {
              e.preventDefault();
              capture();
            }}
          />
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div({
  position: "relative",
});

const CaptureButton = styled.button({
  border: "none",
  background: `url(${TakePhotoImage})`,
  color: colors.white,
  position: "absolute",
  left: "50%",
  bottom: 10,
  transform: "translateX(-50%)",
  width: 45,
  height: 45,
  backgroundSize: "100% 100%",
});

const InfoWrapper = styled.div({
  position: "absolute",
  right: 0,
  top: 0,
  color: colors.black,
  background: "#ffffffaa",
  padding: 5,
  display: "flex",
  flexDirection: "column",
  gap: 5,
  textAlign: "right",
  fontFamily: "monospace",
  fontSize: 12,
  width: 136,
});
