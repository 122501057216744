import { useState } from "react";
import { useSwipeable } from "react-swipeable";
import { PageWrapper } from "./components/lib";

import {
  ContentPage,
  MainMenuPage,
  MapPage,
  SubMenuPage,
  SubmitPhotoPage,
} from "./pages";
import { SettingsPage, ToolsPage, ShuntPage, MetricsPage } from "./pages";
import { contents, pages } from "./values";

export function AuthorizedApp({ sessionId }) {
  const [curPage, setCurPage] = useState(pages.CONTENT);

  const [curMenuIndex, setCurMenuIndex] = useState(-1);
  const [curSubmenuIndex, setCurSubmenuIndex] = useState(-1);
  const [elementId, setElementId] = useState("");

  const [settings, setSettings] = useState({
    locationServices: !!localStorage.getItem("settings.locationServices"),
    addTimestamp:
      localStorage.getItem("settings.addTimestamp") === null ||
      localStorage.getItem("settings.addTimestamp"),
    addHeading:
      localStorage.getItem("settings.addHeading") === null ||
      localStorage.getItem("settings.addHeading"),
    autoSubmit:
      localStorage.getItem("settings.autoSubmit") === null ||
      localStorage.getItem("settings.autoSubmit"),
  });

  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (text) => {
    setSearchText(text);
  };

  const toggleSetting = (settingName) => {
    setSettings((s) => {
      localStorage.setItem(
        `settings.${settingName}`,
        !s[settingName] ? true : ""
      );
      return { ...s, [settingName]: !s[settingName] };
    });
  };

  const showContent = (md, elementId) => {
    const [menuIndex, submenuIndex] = md.split("-");
    setCurMenuIndex(Number(menuIndex) - 1);
    setCurSubmenuIndex(Number(submenuIndex) - 1);
    setElementId(elementId);
  };

  const goBack = () => {
    if (curPage === pages.CONTENT) {
      // if main menu page
      if (curMenuIndex === -1) return;

      // if submenu page
      if (curSubmenuIndex === -1) {
        setElementId("");
        setCurMenuIndex(-1);
        return;
      }

      // if content page
      setElementId("");
      setCurSubmenuIndex(-1);
      // if searchText is not empty, go to the main menu page
      if (searchText) setCurMenuIndex(-1);
      return;
    }

    if (
      curPage === pages.SETTINGS ||
      curPage === pages.SUBMIT_PHOTO ||
      curPage === pages.TOOLS ||
      curPage === pages.MAP
    ) {
      setCurPage(pages.CONTENT);
      return;
    }

    if (
      curPage === pages.SHUNT_CONVERSION ||
      curPage === pages.METRICS_CONVERSION
    ) {
      setCurPage(pages.TOOLS);
      return;
    }
  };

  const logout = () => {
    localStorage.clear();
    window.location.assign("/");
  };

  const handlers = useSwipeable({
    onSwipedRight: goBack,
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  if (curPage === pages.CONTENT) {
    if (curMenuIndex === -1)
      return (
        <MainMenuPage
          contents={contents}
          setCurMenuIndex={setCurMenuIndex}
          goToPage={setCurPage}
          showContent={showContent}
          handlers={handlers}
          searchText={searchText}
          handleSearchTextChange={handleSearchTextChange}
        />
      );
    if (curSubmenuIndex === -1 && contents[curMenuIndex].subcontents)
      return (
        <SubMenuPage
          title={contents[curMenuIndex].title}
          contents={contents[curMenuIndex].subcontents}
          setCurSubmenuIndex={setCurSubmenuIndex}
          handlers={handlers}
          goBack={goBack}
        />
      );
    return (
      <ContentPage
        contents={
          curSubmenuIndex === -1
            ? contents[curMenuIndex]
            : contents[curMenuIndex].subcontents[curSubmenuIndex]
        }
        elementId={elementId}
        searchText={searchText}
        handlers={handlers}
        goBack={goBack}
      />
    );
  }
  if (curPage === pages.SETTINGS) {
    return (
      <SettingsPage
        settings={settings}
        toggleSetting={toggleSetting}
        handlers={handlers}
        goBack={goBack}
        logout={logout}
      />
    );
  }
  if (curPage === pages.SUBMIT_PHOTO) {
    return (
      <SubmitPhotoPage
        settings={settings}
        sessionId={sessionId}
        toggleSetting={toggleSetting}
        handlers={handlers}
        goBack={goBack}
      />
    );
  }
  if (curPage === pages.TOOLS) {
    return (
      <ToolsPage handlers={handlers} goBack={goBack} goToPage={setCurPage} />
    );
  }
  if (curPage === pages.SHUNT_CONVERSION) {
    return (
      <ShuntPage handlers={handlers} goBack={goBack} goToPage={setCurPage} />
    );
  }
  if (curPage === pages.METRICS_CONVERSION) {
    return (
      <MetricsPage handlers={handlers} goBack={goBack} goToPage={setCurPage} />
    );
  }
  if (curPage === pages.MAP) {
    return (
      <MapPage mapAPIKey={process.env.REACT_APP_MAP_API_KEY} handlers={handlers} goBack={goBack} />
    );
  }
  return <PageWrapper>404 No Page</PageWrapper>;
}
