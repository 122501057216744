import { FaAngleLeft } from "react-icons/fa";
import styled from "styled-components";

export function BackButton({ onClick }) {
  return (
    <StyledButton onClick={onClick}>
      <FaAngleLeft size={40} />
    </StyledButton>
  );
}

const StyledButton = styled.button({
  border: "none",
  background: "none",
  padding: 0,
  margin: 0,
  marginRight: 5,
  marginTop: 9,
});
