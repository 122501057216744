import { FaMicrophone, FaSearch, FaTimesCircle } from "react-icons/fa";
import styled from "styled-components";
import { colors } from "../values";

export function SearchInput({ className, value, onChange }) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  const handleClear = () => {
    onChange("");
  };

  return (
    <Wrapper className={className}>
      <FaSearch size={16} />
      <Input
        type="text"
        placeholder="Search"
        value={value}
        onChange={handleChange}
      />
      <OptionContainer>
        {value.length >= 2 && <FaTimesCircle size={16} onClick={handleClear} />}
        {/* <FaMicrophone size={16} /> */}
      </OptionContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  display: "flex",
  alignItems: "center",
  width: "100%",
  borderRadius: 10,
  background: colors.lightGrey3,
  color: "#767680",
  padding: "7px 6px",
});

const Input = styled.input({
  border: "none",
  background: "none",
  fontSize: 17,
  lineHeight: 1.3,
  marginLeft: 6,
  color: "#767680",
  flexGrow: 1,
  "&:active, &:hover": {
    outline: "none",
    border: "none",
  },
});

const OptionContainer = styled.div({
  display: "flex",
  gap: 6,
  marginLeft: "auto",
});
