import { useState } from "react";
import styled from "styled-components";
import { BackButton } from "../components/BackButton";
import { Divider, PageWrapper, Typography } from "../components/lib";
import { colors, shuntTypes } from "../values";

export function ShuntPage({ handlers, goBack }) {
  const [shuntType, setShuntType] = useState(-1);
  const [subType, setSubType] = useState(-1);
  const [measured, setMeasured] = useState("");

  const handleBack = () => {
    if (shuntType === -1 && subType === -1) goBack();
    if (shuntType > -1 && subType === -1) setShuntType(-1);
    if (shuntType > -1 && subType > -1) setSubType(-1);
  };

  return (
    <StyledPageWrapper {...handlers}>
      <Head>
        <BackButton onClick={handleBack} />
        <Typography variant="heading">
          {shuntType === -1 && subType === -1 && "Shunt Values"}
          {shuntType > -1 && subType === -1 && shuntTypes[shuntType].name}
          {shuntType > -1 && subType > -1 && "Calculator"}
        </Typography>
      </Head>
      <Divider marginBottom={5} />
      {shuntType === -1 && subType === -1 && (
        <>
          <SelectText>Select the shunt type to get started</SelectText>
          <CardList>
            {shuntTypes.map((type, i) => {
              const shuntFactors = type.subtypes.map((type) => type["a/mv"]);
              const minFactor = Math.min(...shuntFactors);
              const maxFactor = Math.max(...shuntFactors);

              const ohms = type.subtypes.map((type) => type.ohms);
              const minOhm = Math.min(...ohms);
              const maxOhm = Math.max(...ohms);

              return (
                <CardWrapper key={type.name} onClick={() => setShuntType(i)}>
                  <CardHeader>{type.name}</CardHeader>
                  <CardBody>
                    <p>
                      {[
                        ...new Set(type.subtypes.map((type) => type.name)),
                      ].join(", ")}
                    </p>
                    <p>
                      Shunt Factors:{" "}
                      {maxFactor === minFactor
                        ? minFactor
                        : `${minFactor} - ${maxFactor}`}
                    </p>
                    <p>
                      Ohms:{" "}
                      {maxOhm === minOhm ? minOhm : `${minOhm} - ${maxOhm}`}
                    </p>
                  </CardBody>
                </CardWrapper>
              );
            })}
          </CardList>
        </>
      )}
      {shuntType > -1 && subType === -1 && (
        <CardList>
          {shuntTypes[shuntType].subtypes.map((type, i) => (
            <CardWrapper
              key={type.name + type["a/mv"]}
              onClick={() => setSubType(i)}
            >
              <CardHeader>{type.name}</CardHeader>
              <CardBody>
                <p>Amps: {type.amps}</p>
                <p>mV: {type.mv}</p>
                <p>Ohms: {type.ohms}</p>
                <p>A/mV: {type["a/mv"]}</p>
              </CardBody>
            </CardWrapper>
          ))}
        </CardList>
      )}
      {shuntType > -1 && subType > -1 && (
        <>
          <CardWrapper variant="success">
            <CardHeader>
              {shuntTypes[shuntType].subtypes[subType].name}
            </CardHeader>
            <CardBody>
              <p>Amps: {shuntTypes[shuntType].subtypes[subType].amps}</p>
              <p>mV: {shuntTypes[shuntType].subtypes[subType].mv}</p>
              <p>Ohms: {shuntTypes[shuntType].subtypes[subType].ohms}</p>
              <p>A/mV: {shuntTypes[shuntType].subtypes[subType]["a/mv"]}</p>
            </CardBody>
          </CardWrapper>
          <MeasuredLabel>Millivolts (mV) Measured</MeasuredLabel>
          <MeasuredValue
            type="number"
            pattern="\d*"
            inputMode="numeric"
            value={measured}
            onChange={(e) => setMeasured(e.target.value)}
          />
          <ResultsText>Results</ResultsText>
          <ResultPair>
            <p>Millivolts (mV)</p>
            <p>{Number(measured)} mV</p>
          </ResultPair>
          <ResultPair>
            <p>Amperes (A)</p>
            <p>
              {(
                Number(measured) *
                shuntTypes[shuntType].subtypes[subType]["a/mv"]
              ).toFixed(2)}{" "}
              A
            </p>
          </ResultPair>
          <ResultPair>
            <p>Amps/mV</p>
            <p>{shuntTypes[shuntType].subtypes[subType]["a/mv"]} A/mV</p>
          </ResultPair>
        </>
      )}
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
  gap: 15,
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});

const CardList = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 17,
});

const CardWrapper = styled.div({
  border: ({ variant }) =>
    `1px solid ${variant === "success" ? colors.lightGreen4 : colors.black}`,
  borderRadius: 4,
});

const CardHeader = styled.div({
  padding: "9px 18px",
  borderBottom: `1px solid ${colors.lightGrey4 + "33"}`,
  fontWeight: "bold",
});

const CardBody = styled.div({
  padding: "9px 18px",
  "& p": {
    lineHeight: 1.5,
  },
});

const SelectText = styled.p({});

const MeasuredLabel = styled.p({
  color: colors.grey,
  fontSize: 18,
  marginTop: 12,
});

const MeasuredValue = styled.input({
  color: colors.grey,
  fontSize: 17,
  marginTop: -8,
  padding: 8,
  border: "none",
  borderRadius: 10,
  background: colors.lightGrey2,
});

const ResultsText = styled.p({
  fontSize: 40,
  fontWeight: "bold",
  color: colors.black,
  marginTop: 10,
});

const ResultPair = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "& > p:last-child": {
    fontFamily: "monospace",
    fontWeight: "bold",
    fontSize: "1.3em",
  },
});
