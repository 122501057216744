import React from "react";
import styled from "styled-components";
import "./styles.css";

export function Loader(props) {
  return (
    <div className="bouncing-loader">
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export function FullscreenLoader() {
  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
}

const Wrapper = styled.div({
  position: "fixed",
  width: "100vw",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
