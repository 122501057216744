import { useEffect, useState } from "react";
import { useContentHtmls } from "./contentHtmls";
import { makeDOMFromHTML, rebuildDOMFromSearchText } from "../utils";

export function useSearch(searchText) {
  const { contentHtmls, isLoading } = useContentHtmls();
  const [result, setResult] = useState([]);

  useEffect(() => {
    if (isLoading || !searchText || searchText.length < 2) return;

    const resultArray = [];
    for (const contentMd in contentHtmls) {
      const doc = makeDOMFromHTML(contentHtmls[contentMd]);
      const elements = rebuildDOMFromSearchText(doc, searchText);
      if (elements.length > 0)
        resultArray.push({
          md: contentMd,
          elements,
        });
    }
    setResult(resultArray);
  }, [searchText, isLoading, contentHtmls]);

  return {
    isLoading,
    result,
  };
}
