import styled from "styled-components";
import { colors } from "../../values";

export const Button = styled.button({
  color: colors.white,
  fontSize: 16,
  background: ({ variant = "primary" }) => buttonVariants[variant].background,
  width: "100%",
  padding: 15,
  border: "none",
  borderRadius: 8,
  cursor: "pointer",
  lineHeight: 1.5,
});

const buttonVariants = {
  primary: {
    background: colors.blue,
  },
  secondary: {
    background: colors.grey,
  },
  danger: {
    background: colors.lightRed,
  },
  success: {
    background: colors.lightGreen,
  },
};
