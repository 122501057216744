export function formatHeading(heading) {
  return (
    "Facing " +
    (heading <= 30
      ? "North"
      : heading <= 60
      ? "North West"
      : heading <= 120
      ? "West"
      : heading <= 150
      ? "South West"
      : heading <= 210
      ? "South"
      : heading <= 240
      ? "South East"
      : heading <= 300
      ? "East"
      : heading <= 330
      ? "North East"
      : "North")
  );
}
