import { useState } from "react";
import { FaAngleDown, FaAngleUp, FaStackOverflow } from "react-icons/fa";
import styled from "styled-components";
import { colors, titles } from "../values";
import { highlightText } from "../utils";

export function SearchResults({ result, searchText, showContent }) {
  const [openedMd, setOpenedMd] = useState("");

  const handleMdClick = (md) => {
    if (md === openedMd) setOpenedMd(-1);
    else setOpenedMd(md);
  };

  const handleElementClick = (md, elementId) => {
    showContent(md, elementId);
  };

  return result.length === 0 ? (
    <NoResultText>No Search Result</NoResultText>
  ) : (
    <MdList>
      {result.map((resultItem) => (
        <MdItem key={resultItem.md}>
          <MdItemHeader onClick={() => handleMdClick(resultItem.md)}>
            <MdItemLeft>
              {resultItem.md} {titles[resultItem.md]}
            </MdItemLeft>
            <MdItemRight>
              <p>{resultItem.elements.length} found.</p>
              {openedMd === resultItem.md ? <FaAngleUp /> : <FaAngleDown />}
            </MdItemRight>
          </MdItemHeader>
          {openedMd === resultItem.md && (
            <MdItemElementList>
              {resultItem.elements.map((element) => (
                <MdItemElement
                  key={element.id}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleElementClick(resultItem.md, element.id);
                  }}
                  dangerouslySetInnerHTML={{
                    __html: highlightText(element.text, searchText),
                  }}
                />
              ))}
            </MdItemElementList>
          )}
        </MdItem>
      ))}
    </MdList>
  );
}

const MdList = styled.ul({
  margin: 0,
  padding: 0,
  listStyle: "none",
  display: "flex",
  flexDirection: "column",
  gap: 10,
});

const MdItem = styled.li({
  margin: 0,
  padding: 0,
  border: `1px solid ${colors.grey}`,
  borderRadius: 10,
  // background: colors.lightGrey2,
});

const MdItemHeader = styled.div({
  padding: 10,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontWeight: "bold",
});

const MdItemElementList = styled.ul({
  padding: "0 10px 10px",
  listStyle: "none",
  display: "flex",
  flexDirection: "column",
  gap: 8,
});

const MdItemElement = styled.li({
  border: `1px solid ${colors.grey}`,
  padding: 5,
  "&:hover": {
    background: colors.lightGrey3,
  },
});

const NoResultText = styled.div({
  padding: "0 10px",
});

const MdItemLeft = styled.p({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
});

const MdItemRight = styled.div({
  display: "flex",
  alignItems: "center",
  gap: 10,
  whiteSpace: "nowrap",
  marginLeft: 20,
});
