export const colors = {
  white: "#ffffff",
  black: "#152536",
  blue: "#0D6EFD",
  grey: "#6C757D",
  lightGrey: "#CED4DA",
  lightGrey2: "#D9D9D9",
  lightGrey3: "#efeff0",
  lightGrey4: "#343A40",
  darkGrey: "#333333",
  red: "#ff0000",
  darkRed: "#842029",
  lightRed: "#FF4242",
  lightRed2: "#F8D7DA",
  lightRed3: "#F1AEB5",
  lightRed4: "#DC3545",
  darkGreen: "#0F5132",
  lightGreen: "#0AA422",
  lightGreen2: "#D1E7DD",
  lightGreen3: "#A3CFBB",
  lightGreen4: "#01BB4C",
};
