import styled from "styled-components";
import { BackButton } from "../components/BackButton";
import { Button, Divider, PageWrapper, Typography } from "../components/lib";

export function SubMenuPage({
  title,
  contents,
  setCurSubmenuIndex,
  handlers,
  goBack,
}) {
  const handleClick = (i) => setCurSubmenuIndex(i);

  return (
    <StyledPageWrapper {...handlers}>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">{title}</Typography>
      </Head>
      <Divider marginBottom={10} />
      {contents.map((content, i) => (
        <Button key={content.title} onClick={() => handleClick(i)}>
          {content.title}
        </Button>
      ))}
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: 15,
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});
