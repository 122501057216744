import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useAuth() {
  const [authorized, setAuthorized] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (!token) {
      if (localStorage.getItem("session_id")) {
        setSessionId(localStorage.getItem("session_id"));
      }
    } else {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_AUTH_API}?token=${token}`)
        .then((response) => response.data)
        .then((response) => {
          setSessionId(response["session_id"]);
          localStorage.setItem("session_id", response["session_id"]);
        })
        .catch((e) => {
          setIsLoading(false);
          setError(e.response.data["reason"]);
        });
    }
  }, [token]);

  useEffect(() => {
    if (!sessionId) return;
    setIsLoading(true);
    axios
      .post(
        process.env.REACT_APP_LOG_API,
        {},
        {
          headers: { Authorization: `Bearer ${sessionId}` },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        if (response["result"] === "OK") {
          setAuthorized(true);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError("UNKNOWN_ERROR");
        }
      })
      .catch((e) => {
        setIsLoading(false);
        setError(e.response.data["error"]);
      });
  }, [sessionId]);

  return { authorized, sessionId, isLoading, isError: !!error, error };
}
