import styled from "styled-components";
import { BackButton } from "../components/BackButton";
import { Divider, PageWrapper, Typography } from "../components/lib";

export function MetricsPage({ handlers, goBack }) {
  return (
    <StyledPageWrapper {...handlers}>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">Metrics Conversion</Typography>
      </Head>
      <Divider marginBottom={10} />
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: 15,
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});
