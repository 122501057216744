/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";
import { colors } from "../values";
import { Typography } from "./lib";

export function RequestSubmitted({ setRequested, ...props }) {
  return (
    <Wrapper {...props}>
      <Typography variant="subtitle" textAlign="left">
        Request Submitted
      </Typography>
      <Description1>
        Your request for access has been submitted. Expect to receive an email
        or text within a few hours! Thank you!
      </Description1>
      <Description2>
        If you need further support, please contact{" "}
        <a
          href="https://support@engineeringdirector.com"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>{" "}
        or <a onClick={() => setRequested(false)}>Resend request</a>
      </Description2>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  color: colors.darkGreen,
  backgroundColor: colors.lightGreen2,
  border: `1px solid ${colors.lightGreen3}`,
  textAlign: "center",
  marginTop: 12,
  marginBottom: 16,
  borderRadius: 5,
  padding: 16,
});

const Description1 = styled(Typography)({
  marginTop: 10,
});

const Description2 = styled(Typography)({
  marginTop: 20,
});
