import { useState } from "react";
import styled from "styled-components";
import { Divider, PageWrapper, Typography } from "../components/lib";
import { RequestForm } from "../components/RequestForm";
import { RequestSubmitted } from "../components/RequestSubmitted";
import { colors, errorMessages } from "../values";

export function OpeningPage({ error, handlers }) {
  const [requested, setRequested] = useState(
    !!localStorage.getItem("requested")
  );

  const setRequestedLocalStorage = (b) => {
    setRequested(b);
    localStorage.setItem("requested", b ? "true" : "");
  };

  return (
    <StyledPageWrapper {...handlers}>
      <Typography variant="heading">Techinician Field Guide</Typography>
      <Divider margin={15} />
      <Typography variant="bodytext">
        To login to the field guide, you need an authorization code or a QR
        code. If you don't have one, you can request at the form below.
      </Typography>
      {error && !requested && <ErrorLabel>{errorMessages[error]}</ErrorLabel>}
      {requested ? (
        <RequestSubmitted
          setRequested={setRequestedLocalStorage}
          style={{ marginTop: 24 }}
        />
      ) : (
        <RequestForm
          setRequested={setRequestedLocalStorage}
          style={{ marginTop: error ? 0 : 24 }}
        />
      )}
      {!requested && <Divider margin={20} />}
      <FooterTypography
        variant="bodytext"
        textAlign="center"
        marginTop={requested ? "auto" : "unset"}
      >
        Unauthorized access is prohibited and all attempts to login are
        monitored and routinely audited.
      </FooterTypography>
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
});

const FooterTypography = styled(Typography)({
  marginTop: ({ marginTop }) => marginTop,
});

const ErrorLabel = styled.p({
  color: colors.darkRed,
  backgroundColor: colors.lightRed2,
  border: `1px solid ${colors.lightRed3}`,
  textAlign: "center",
  marginTop: 12,
  marginBottom: 16,
  borderRadius: 5,
  padding: 16,
});
