import styled from "styled-components";
import { Divider, Loader, PageWrapper, Typography } from "../components/lib";
import { BackButton } from "../components/BackButton";

import { useEffect, useRef } from "react";
import {
  useContentHtmls,
  makeDOMFromHTML,
  rebuildDOMFromSearchText,
} from "../utils";

export function ContentPage({
  contents,
  elementId,
  searchText,
  handlers,
  goBack,
}) {
  const { title, content } = contents;
  const { isLoading, contentHtmls } = useContentHtmls();

  const ref = useRef();

  useEffect(() => {
    let dom = makeDOMFromHTML(contentHtmls[content]);
    if (searchText.length >= 2) rebuildDOMFromSearchText(dom, searchText);
    ref.current.appendChild(dom);
    if (elementId) {
      document
        .getElementById(elementId)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [content, contentHtmls, searchText, elementId]);

  return (
    <StyledPageWrapper {...handlers}>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">{title}</Typography>
      </Head>
      <Divider />
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <ContentsWrapper ref={ref}></ContentsWrapper>
      )}
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: 15,
});

const ContentsWrapper = styled.div({
  flexGrow: 1,
  overflowY: "auto",
  "& img": {
    width: "90%",
    display: "block",
    margin: "20px auto 10px",
    "& + strong": {
      display: "block",
      margin: "0px auto 20px",
      textAlign: "center",
    },
  },
});

const LoaderWrapper = styled.div({
  flexGrow: 1,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});
