import * as olSource from "ol/source";

function bingMaps() {
  return new olSource.BingMaps({
    key: "AkJXHF_4pQ_rTQ_VV6W-ojLskDku0Cfvxk_VdA8kNOmbkteIs6W-DvAs48C2dF2X",
    maxZoom: 15,
    imagerySet: "Aerial",
  });
}

export default bingMaps;
