import styled from "styled-components";
import { colors } from "../../values";

export function Divider({
  text,
  color = colors.lightGrey,
  backgroundColor = colors.white,
  ...props
}) {
  return (
    <StyledDiv
      text={text}
      color={color}
      backgroundColor={backgroundColor}
      {...props}
    />
  );
}

export const StyledDiv = styled.div({
  position: "relative",
  height: 1,
  background: ({ color }) => color,
  marginTop: ({ marginTop }) =>
    typeof marginTop === "number" ? marginTop + "px" : marginTop,
  marginBottom: ({ marginBottom }) =>
    typeof marginBottom === "number" ? marginBottom + "px" : marginBottom,
  marginBlock: ({ margin }) =>
    typeof margin === "number" ? margin + "px" : margin,
  "&:after": {
    content: ({ text }) => `"${text}"`,
    display: ({ text }) => (text ? "block" : "none"),
    position: "absolute",
    color: ({ color }) => color,
    background: ({ backgroundColor }) => backgroundColor,
    left: "50%",
    paddingInline: 10,
    transform: "translate(-50%, -50%)",
  },
});
