import styled from "styled-components";
import { Button, Divider, PageWrapper, Typography } from "../components/lib";
import { SearchInput } from "../components/SearchInput";
import { SearchResults } from "../components/SearchResults";
import { FaCamera, FaCog, FaMap } from "react-icons/fa";
import { useSearch } from "../utils";
import { pages } from "../values";

export function MainMenuPage({
  contents,
  setCurMenuIndex,
  goToPage,
  showContent,
  handlers,
  searchText,
  handleSearchTextChange,
}) {
  const handleClick = (i) => setCurMenuIndex(i);

  const { isLoading: isSearching, result: searchResult } =
    useSearch(searchText);

  return (
    <PageWrapper {...handlers}>
      <Head>
        <Typography variant="heading">Main Menu</Typography>
        <SettingsButton onClick={() => goToPage(pages.SETTINGS)}>
          <FaCog />
        </SettingsButton>
      </Head>
      <Divider margin={15} />
      <ContentsWrapper>
        <StyledSearchInput
          value={searchText}
          onChange={handleSearchTextChange}
        />
        {searchText.length >= 2 && isSearching ? (
          <SearchingText>Searching ...</SearchingText>
        ) : searchText.length >= 2 && searchResult ? (
          <SearchResults
            result={searchResult}
            searchText={searchText}
            showContent={showContent}
          />
        ) : (
          <>
            {contents.slice(0, contents.length - 1).map((content, i) => (
              <Button key={content.title} onClick={() => handleClick(i)}>
                {content.title}
              </Button>
            ))}
            <Button key="Tools" onClick={() => goToPage(pages.TOOLS)}>
              Tools
            </Button>
            <Button
              key={contents[contents.length - 1].title}
              onClick={() => handleClick(contents.length - 1)}
            >
              {contents[contents.length - 1].title}
            </Button>
            <IconButton
              key="Submit Photos"
              onClick={() => goToPage(pages.SUBMIT_PHOTO)}
            >
              <FaCamera /> Submit Photos
            </IconButton>
            <IconButton key="Map" onClick={() => goToPage(pages.MAP)}>
              <FaMap /> Nearby Map
            </IconButton>
          </>
        )}
      </ContentsWrapper>
    </PageWrapper>
  );
}

const ContentsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 15,
  marginTop: 15,
});

const Head = styled.div({
  display: "flex",
  alignItems: "center",
});

const SettingsButton = styled.button({
  marginLeft: "auto",
  border: "none",
  background: "none",
  "& > svg": {
    width: 30,
    height: 30,
  },
});

const IconButton = styled(Button)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: 10,
  "& > svg": {
    marginBottom: 3,
  },
});

const StyledSearchInput = styled(SearchInput)({
  marginBlock: 5,
});

const SearchingText = styled.div({
  padding: "0 10px",
});
