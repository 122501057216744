import styled from "styled-components";
import { colors } from "../../values";

export const PageWrapper = styled.div({
  position: "relative",
  minHeight: "100%",
  padding: 20,
  marginLeft: "auto",
  marginRight: "auto",
  color: colors.grey,
});
