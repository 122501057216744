import { useCallback, useEffect, useState } from "react";

export function useGeolocation() {
  const [geolocation, setGeolocation] = useState({
    latitude: 0,
    longitude: 0,
    heading: 0,
    error: null,
  });

  const onSuccess = useCallback((pos) => {
    const crd = pos.coords;
    setGeolocation({
      latitude: crd.latitude,
      longitude: crd.longitude,
      // longitude: -72.6,
      // latitude: 50,
      heading: crd.heading,
      error: null,
    });
  }, []);

  const onError = useCallback((err) => {
    setGeolocation({ error: "Can't watch position" });
  }, []);

  useEffect(() => {
    let id;

    id = navigator.geolocation.watchPosition(onSuccess, onError, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: 0,
    });

    const onChange = () => {
      if (document.hidden) {
        navigator.geolocation.clearWatch(id);
      } else {
        id = navigator.geolocation.watchPosition(onSuccess, onError, {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 0,
        });
      }
    };

    document.addEventListener("visibilitychange", onChange);

    return () => {
      navigator.geolocation.clearWatch(id);

      document.removeEventListener("visibilitychange", onChange);
    };
  }, [onError, onSuccess]);

  return geolocation;
}
