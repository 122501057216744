import styled from "styled-components";
import { colors } from "../../values";

export const Typography = ({
  variant = "bodytext",
  textAlign = "left",
  className,
  children,
}) => {
  const Component = typographyVariants[variant];

  return (
    <Component
      variant={variant}
      textAlign={textAlign}
      className={className}
      children={children}
    />
  );
};

const typographyVariants = {
  heading: styled.h1({
    color: colors.black,
    fontSize: 40,
    fontWeight: "bold",
    letterSpacing: -1,
    textAlign: ({ textAlign }) => textAlign,
  }),
  title: styled.h2({
    color: colors.black,
    fontSize: 40,
    fontWeight: "bold",
    letterSpacing: -1,
    textAlign: ({ textAlign }) => textAlign,
  }),
  subtitle: styled.p({
    fontSize: 24,
    fontWeight: "bold",
    textAlign: ({ textAlign }) => textAlign,
  }),
  bodytext: styled.p({
    color: "inherit",
    fontSize: 18,
    textAlign: ({ textAlign }) => textAlign,
  }),
};
