export function rebuildDOMFromSearchText(doc, searchText) {
  const walker = document.createTreeWalker(doc, NodeFilter.SHOW_ALL);
  let currentNode = walker.currentNode;

  const elements = [];

  while (currentNode) {
    const nextNode = walker.nextNode();
    if (
      currentNode.nodeType === Node.TEXT_NODE &&
      currentNode.textContent !== "\n" &&
      currentNode.textContent !== ""
    ) {
      const nextSibling = currentNode.nextSibling;
      const parentElement = currentNode.parentElement;
      parentElement.removeChild(currentNode);

      let text = currentNode.textContent;
      let found = false;
      while (true) {
        const i = text.search(new RegExp(searchText, "i"));
        if (i !== -1) {
          found = true;

          parentElement.insertBefore(
            document.createTextNode(text.slice(0, i)),
            nextSibling
          );

          const markNode = document.createElement("mark");
          markNode.appendChild(
            document.createTextNode(text.slice(i, i + searchText.length))
          );
          parentElement.insertBefore(markNode, nextSibling);

          text = text.slice(i + searchText.length);
        } else {
          parentElement.insertBefore(
            document.createTextNode(text),
            nextSibling
          );
          break;
        }
      }
      if (found)
        elements.push({
          id: parentElement.id,
          text: currentNode.textContent,
        });
    }

    currentNode = nextNode;
  }

  return elements;
}
