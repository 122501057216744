export function makeDOMFromHTML(htmlString) {
  if (!htmlString) return document.createElement("div");

  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const walker = document.createTreeWalker(doc, NodeFilter.SHOW_ALL);
  let currentNode = walker.currentNode;

  let idCounter = 1;

  while (currentNode) {
    const nextNode = walker.nextNode();
    if (
      currentNode.nodeType === Node.TEXT_NODE &&
      currentNode.textContent !== "\n" &&
      currentNode.textContent !== ""
    ) {
      const parentElement = currentNode.parentElement;
      if (!parentElement.id) {
        parentElement.id = idCounter++;
      }
    }
    currentNode = nextNode;
  }

  const body = doc.querySelector("body");
  const div = document.createElement("div");
  for (const child of body.childNodes) {
    div.appendChild(child);
  }

  return div;
}
