import React, { useEffect, useMemo, useState } from "react";
import Map from "./Map";
import { Layers, TileLayer, VectorLayer } from "./Layers";
import { Style, Icon } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { vector, bingMaps, bboxVector } from "./Source";
import { fromLonLat } from "ol/proj";
// import { Controls, FullScreenControl } from "./Controls";
import axios from "axios";
import { Loader } from "../../components/lib";
import MarkerImage from "../../assets/marker.png";
import styled from "styled-components";

function addMarkers(lonLatArray) {
  var iconStyle = new Style({
    image: new Icon({
      anchorXUnits: "fraction",
      anchorYUnits: "pixels",
      src: MarkerImage,
    }),
  });
  let features = lonLatArray.map((item) => {
    let feature = new Feature({
      geometry: new Point(fromLonLat(item)),
    });
    feature.setStyle(iconStyle);
    return feature;
  });
  return features;
}

let FieldMap = ({ longitude, latitude, mapAPIKey, showMarker, className }) => {
  const [zoom, setZoom] = useState(7);
  const [features, setFeatures] = useState(addMarkers([[longitude, latitude]]));
  const [layers, setLayers] = useState(null);

  const center = useMemo(
    () => fromLonLat([longitude, latitude]),
    [latitude, longitude]
  );

  useEffect(() => {
    if (!mapAPIKey) return;
    axios
      .get(
        "https://secure.engineeringdirector.com/partner/mapserver/fieldguideapp",
        {
          headers: {
            Authorization: `Bearer ${mapAPIKey}`,
          },
        }
      )
      .then((response) => response.data)
      .then((response) => {
        setLayers(response.layers);
      });
  }, [mapAPIKey]);

  return !layers ? (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  ) : (
    <Map center={center} zoom={zoom} className={className}>
      <Layers>
        <TileLayer source={bingMaps()} zIndex={0} />
        {showMarker && <VectorLayer source={vector({ features })} />}
        {layers.map((layerResponse) => (
          <VectorLayer
            key={layerResponse.row_order}
            source={bboxVector({ layerId: layerResponse.row_order, mapAPIKey })}
          />
        ))}
      </Layers>
      {/* <Controls>
        <FullScreenControl />
      </Controls> */}
    </Map>
  );
};

FieldMap = React.memo(FieldMap);

const LoaderWrapper = styled.div({
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export { FieldMap };
