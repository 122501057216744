export const titles = {
  "1-1": "Measurement Equipment",
  "1-2": "Measurement Equipment Maintenance",
  "1-3": "Pipeline CP Measurement Procedures",
  "1-4": "Rectifier Inspection and Measurement Procedures",
  "1-5": "Reading Shunts and Bonds",
  "1-6": "Locating Buried Cables and Anodes",
  "2-1": "Exposed Pipe Inspection",
  "2-2": "Corrosion Damage Inspection",
  "2-3": "Coating Condition Inspection",
  4: "Contacts & Resources",
};

export const contents = [
  {
    title: "CP Measurements",
    subcontents: [
      {
        title: titles["1-1"],
        content: "1-1",
      },
      {
        title: titles["1-2"],
        content: "1-2",
      },
      {
        title: titles["1-3"],
        content: "1-3",
      },
      {
        title: titles["1-4"],
        content: "1-4",
      },
      {
        title: titles["1-5"],
        content: "1-5",
      },
      {
        title: titles["1-6"],
        content: "1-6",
      },
    ],
  },
  {
    title: "External Corrosion Evaluation",
    subcontents: [
      {
        title: titles["2-1"],
        content: "2-1",
      },
      {
        title: titles["2-2"],
        content: "2-2",
      },
      {
        title: titles["2-3"],
        content: "2-3",
      },
    ],
  },
  {
    title: titles["4"],
    content: "4",
  },
];
