export const shuntTypes = [
  {
    name: "Holloway Type",
    subtypes: [
      {
        name: "RS",
        amps: 5,
        mv: 50,
        ohms: 0.01,
        "a/mv": 0.1,
      },
      {
        name: "SS",
        amps: 25,
        mv: 25,
        ohms: 0.001,
        "a/mv": 1,
      },
      {
        name: "SO",
        amps: 50,
        mv: 50,
        ohms: 0.001,
        "a/mv": 1,
      },
      {
        name: "SW or CP",
        amps: 1,
        mv: 50,
        ohms: 0.05,
        "a/mv": 0.02,
      },
      {
        name: "SW or CP",
        amps: 2,
        mv: 50,
        ohms: 0.025,
        "a/mv": 0.04,
      },
      {
        name: "SW or CP",
        amps: 3,
        mv: 50,
        ohms: 0.017,
        "a/mv": 0.06,
      },
      {
        name: "SW or CP",
        amps: 4,
        mv: 50,
        ohms: 0.0125,
        "a/mv": 0.08,
      },
      {
        name: "SW or CP",
        amps: 5,
        mv: 50,
        ohms: 0.01,
        "a/mv": 0.1,
      },
      {
        name: "SW or CP",
        amps: 10,
        mv: 50,
        ohms: 0.005,
        "a/mv": 0.2,
      },
      {
        name: "SW",
        amps: 15,
        mv: 50,
        ohms: 0.0033,
        "a/mv": 0.3,
      },
      {
        name: "SW",
        amps: 20,
        mv: 50,
        ohms: 0.0025,
        "a/mv": 0.4,
      },
      {
        name: "SW",
        amps: 25,
        mv: 50,
        ohms: 0.002,
        "a/mv": 0.5,
      },
      {
        name: "SW",
        amps: 30,
        mv: 50,
        ohms: 0.0017,
        "a/mv": 0.6,
      },
      {
        name: "SW",
        amps: 50,
        mv: 50,
        ohms: 0.001,
        "a/mv": 1,
      },
      {
        name: "SW",
        amps: 60,
        mv: 50,
        ohms: 0.0008,
        "a/mv": 1.2,
      },
      {
        name: "SW",
        amps: 75,
        mv: 50,
        ohms: 0.00067,
        "a/mv": 1.5,
      },
      {
        name: "SW",
        amps: 100,
        mv: 50,
        ohms: 0.0005,
        "a/mv": 2,
      },
    ],
  },
  {
    name: "J.B. Type",
    subtypes: [
      {
        name: "Agra-Mesa",
        amps: 5,
        mv: 50,
        ohms: 0.01,
        "a/mv": 0.1,
      },
    ],
  },
  {
    name: "Cott or MCM",
    subtypes: [
      {
        name: "Red (MCM)",
        amps: 2,
        mv: 200,
        ohms: 0.1,
        "a/mv": 0.01,
      },
      {
        name: "Red (Cott)",
        amps: 0.5,
        mv: 50,
        ohms: 0.1,
        "a/mv": 0.01,
      },
      {
        name: "Yellow (MCM)",
        amps: 8,
        mv: 80,
        ohms: 0.01,
        "a/mv": 0.1,
      },
      {
        name: "Orange (MCM)",
        amps: 25,
        mv: 25,
        ohms: 0.001,
        "a/mv": 1,
      },
    ],
  },
];
