import { Vector as VectorSource } from "ol/source";
import GeoJSON from "ol/format/GeoJSON";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import { toLonLat, get, fromLonLat } from "ol/proj";
import axios from "axios";
import { Style, Icon, Stroke } from "ol/style";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import MarkerImage from "../../../assets/marker.png";
import { LineString, MultiLineString } from "ol/geom";

function bboxVector({ layerId, mapAPIKey }) {
  const vectorSource = new VectorSource({
    format: new GeoJSON(),
    // url: function (extent) {
    //   const minLonlat = toLonLat([extent[0], extent[1]], get("EPSG:3857"));
    //   const maxLonlat = toLonLat([extent[2], extent[3]], get("EPSG:3857"));
    //   return `https://secure.engineeringdirector.com/partner/mapserver/fieldguideapp/0/bbox_query?xmax=${maxLonlat[0]}&xmin=${minLonlat[0]}&ymax=${maxLonlat[1]}&ymin=${minLonlat[1]}&srid=4326`;
    // },
    strategy: bboxStrategy,
    loader: function (extent, resolution, projection, success, failure) {
      console.log(extent);

      if (!mapAPIKey) return;

      const minLonlat = toLonLat([extent[0], extent[1]], get("EPSG:3857"));
      const maxLonlat = toLonLat([extent[2], extent[3]], get("EPSG:3857"));
      const url = `https://secure.engineeringdirector.com/partner/mapserver/fieldguideapp/${layerId}/bbox_query?xmax=${maxLonlat[0]}&xmin=${minLonlat[0]}&ymax=${maxLonlat[1]}&ymin=${minLonlat[1]}&srid=4326`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${mapAPIKey}`,
          },
        })
        .then((response) => response.data)
        .then((response) => {
          let features = [];
          if (response.layer.asset_type.geo_type === "multilinestring") {
            const lineStyle = new Style({
              stroke: new Stroke({
                color: response.layer.asset_type.line_stroke_color,
                width: response.layer.asset_type.line_stroke_width,
              }),
            });
            features = response.features.map((featureResponse) => {
              if (featureResponse.location.type === "multilinestring") {
                let feature = new Feature({
                  geometry: new MultiLineString(
                    featureResponse.location.coordinates.map(
                      (lineStringCoords) =>
                        new LineString(
                          lineStringCoords.map((coordinate) =>
                            fromLonLat(coordinate)
                          )
                        )
                    )
                  ),
                });
                feature.setStyle(lineStyle);
                return feature;
              }
              return null;
            });
          }
          if (response.layer.asset_type.geo_type === "point") {
            let iconStyle = new Style({
              image: new Icon({
                anchorXUnits: "fraction",
                anchorYUnits: "pixels",
                src: response.layer.asset_type.icon_path,
              }),
            });
            features = response.features.map((featureResponse) => {
              if (featureResponse.location.type === "point") {
                let feature = new Feature({
                  geometry: new Point(
                    fromLonLat(featureResponse.location.coordinates)
                  ),
                });
                feature.setStyle(iconStyle);
                return feature;
              }
              return null;
            });
          }
          console.log(features);
          vectorSource.addFeatures(features);
          success(features);
        });
    },
  });

  return vectorSource;
}

export default bboxVector;
