import { marked } from "marked";
import { createContext, useContext, useEffect, useState } from "react";

const contentMds = [
  "1-1",
  "1-2",
  "1-3",
  "1-4",
  "1-5",
  "1-6",
  "2-1",
  "2-2",
  "2-3",
  "3-1",
  "3-2",
  "4",
];

const ContentHtmlsContext = createContext();

function useContentHtmls() {
  const value = useContext(ContentHtmlsContext);
  if (!value)
    throw new Error("useContentHtmls must be used within ContentHtmlsProvider");
  return value;
}

function ContentHtmlsProvider({ children }) {
  const [contentHtmls, setContentHtmls] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    Promise.all(
      contentMds.map((contentMd) =>
        import(`../../src/contents/${contentMd}.md`)
          .then((res) => fetch(res.default))
          .then((res) => res.text())
          .then((res) => marked(res))
      )
    ).then((res) => {
      const obj = {};
      for (let i = 0; i < res.length; i++) {
        obj[contentMds[i]] = res[i];
      }
      setContentHtmls(obj);
      setIsLoading(false);
    });
  }, []);

  return (
    <ContentHtmlsContext.Provider value={{ contentHtmls, isLoading }}>
      {children}
    </ContentHtmlsContext.Provider>
  );
}

export { useContentHtmls, ContentHtmlsProvider };
