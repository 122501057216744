import styled from "styled-components";
import { BackButton } from "../components/BackButton";
import { Button, Divider, PageWrapper, Typography } from "../components/lib";
import { colors } from "../values";

export function SettingsPage({
  settings,
  toggleSetting,
  handlers,
  goBack,
  logout,
}) {
  // const { error } = useGeolocation();

  return (
    <StyledPageWrapper {...handlers}>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">Settings</Typography>
      </Head>
      <Divider margin={15} />
      <ContentsWrapper>
        <SettingsItem>
          <SettingsLabel>Current Version</SettingsLabel>
          <VersionSettingsValue>
            1.0.{process.env.REACT_APP_VERSION}
          </VersionSettingsValue>
        </SettingsItem>
        {/* <SettingsItem>
          <SettingsLabel>Location Services</SettingsLabel>
          <SettingsValue>
            {error ? (
              <DisabledLabel>Disabled</DisabledLabel>
            ) : settings.locationServices ? (
              <OptionButton
                variant="success"
                onClick={() => toggleSetting("locationServices")}
              >
                On
              </OptionButton>
            ) : (
              <OptionButton
                variant="secondary"
                onClick={() => toggleSetting("locationServices")}
              >
                Off
              </OptionButton>
            )}
          </SettingsValue>
        </SettingsItem> */}
      </ContentsWrapper>
      <LogoutButton variant="danger" onClick={logout}>
        Logout
      </LogoutButton>
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});

const ContentsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 15,
});

const SettingsItem = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const SettingsLabel = styled.p({
  fontSize: 20,
  color: colors.grey,
});

const SettingsValue = styled.p({
  fontSize: 20,
  color: colors.grey,
});

const VersionSettingsValue = styled(SettingsValue)({
  marginRight: 0,
});

const LogoutButton = styled(Button)({
  marginTop: "auto",
});
