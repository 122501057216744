import { AuthorizedApp } from "./AuthorizedApp";
import { FullscreenLoader } from "./components/lib";
import { OpeningPage } from "./pages";
import { useAuth } from "./utils";
import { ContentHtmlsProvider } from "./utils/contentHtmls";

function App() {
  const { authorized, sessionId, isLoading, error } = useAuth();

  if (isLoading) return <FullscreenLoader />;
  return authorized ? (
    <ContentHtmlsProvider>
      <AuthorizedApp sessionId={sessionId} />
    </ContentHtmlsProvider>
  ) : (
    <OpeningPage error={error} />
  );
}

export default App;
