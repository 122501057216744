import styled from "styled-components";
import { colors } from "../../values";

export const Input = styled.input({
  background: colors.white,
  padding: 10,
  color: colors.black,
  border: "none",
  outline: "none",
  fontSize: 17,
  lineHeight: '22px',
  width: "100%",
});
