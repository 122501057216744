/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { BackButton } from "../components/BackButton";
import {
  Button,
  Divider,
  Loader,
  PageWrapper,
  Typography,
} from "../components/lib";
import { WebcamCapture } from "../components/WebCam";
import { useGeolocation } from "../utils";
import { colors } from "../values";

const STATUS = {
  LOADING: "loading",
  INITIALIZED: "initialized",
  LOAD_FAILED: "load_failed",
  SUBMITTING: "submitting",
  SUBMIT_SUCCESS: "submit_success",
  SUBMIT_FAILED: "submit_failed",
};

export function SubmitPhotoPage({
  settings,
  sessionId,
  toggleSetting,
  handlers,
  goBack,
}) {
  const [status, setStatus] = useState(STATUS.LOADING);
  const [image, setImage] = useState("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const geolocation = useGeolocation();

  const retake = useCallback(() => {
    setStatus(STATUS.INITIALIZED);
    setImage("");
  }, []);

  const submitPhoto = useCallback((image, latitude, longitude) => {
    setStatus(STATUS.SUBMITTING);
    axios
      .post(
        process.env.REACT_APP_PHOTO_API,
        {
          latitude,
          longitude,
          photo_base64: image.slice(22),
        },
        {
          headers: {
            Authorization: `Bearer ${sessionId}`,
          },
        }
      )
      .then((response) => {
        setStatus(STATUS.SUBMIT_SUCCESS);
      })
      .catch((e) => {
        setStatus(STATUS.SUBMIT_FAILED);
      });
  }, [sessionId]);

  const handleTake = useCallback(
    (image, latitude, longitude) => {
      setImage(image);
      setLatitude(latitude);
      setLongitude(longitude);
      if (settings.autoSubmit) {
        submitPhoto(image, latitude, longitude);
      }
    },
    [settings.autoSubmit, submitPhoto]
  );

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(function (stream) {
        setStatus(STATUS.INITIALIZED);
      })
      .catch(function (err) {
        setStatus(STATUS.LOAD_FAILED);
      });
  }, []);

  return (
    <PageWrapper {...handlers}>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">Submit Photo</Typography>
      </Head>
      <Divider margin={15} />
      {status === STATUS.LOADING ? null : status === STATUS.LOAD_FAILED ? (
        <ErrorWrapper>
          <ErrorTitle>Cannot Open Camera</ErrorTitle>
          <ErrorBody>
            Looks like we cannot open the camera device on your phone. Please
            make sure your phone supports modern web-based cameras and you have
            permissions enabled.
          </ErrorBody>
        </ErrorWrapper>
      ) : (
        <>
          <WebcamCapture
            image={image}
            setImage={setImage}
            setLatitude={setLatitude}
            setLongitude={setLongitude}
            onTake={handleTake}
            settings={settings}
            geolocation={geolocation}
          />
          {status === STATUS.SUBMIT_SUCCESS && (
            <SuccessLabel>
              Successfully uploaded photo. <a onClick={retake}>Retake</a>
            </SuccessLabel>
          )}
          {status === STATUS.SUBMIT_FAILED && (
            <ErrorLabel>
              Failed to upload photo. <a onClick={retake}>Retake</a>
            </ErrorLabel>
          )}
          {status === STATUS.INITIALIZED && image && (
            <SuccessLabel>
              Taken photo.{" "}
              <a onClick={() => submitPhoto(image, latitude, longitude)}>
                Submit
              </a>{" "}
              or <a onClick={retake}>Retake</a>
            </SuccessLabel>
          )}
          <SettingsList>
            <SettingsItem>
              <SettingsLabel>Add Location</SettingsLabel>
              <SettingsOption>
                {geolocation.error ? (
                  <DisabledLabel>Disabled</DisabledLabel>
                ) : settings.locationServices ? (
                  <OptionButton
                    variant="success"
                    onClick={() => toggleSetting("locationServices")}
                  >
                    On
                  </OptionButton>
                ) : (
                  <OptionButton
                    variant="secondary"
                    onClick={() => toggleSetting("locationServices")}
                  >
                    Off
                  </OptionButton>
                )}
              </SettingsOption>
            </SettingsItem>
            <SettingsItem>
              <SettingsLabel>Add Timestamp</SettingsLabel>
              <SettingsOption>
                {settings.addTimestamp ? (
                  <OptionButton
                    variant="success"
                    onClick={() => toggleSetting("addTimestamp")}
                  >
                    On
                  </OptionButton>
                ) : (
                  <OptionButton
                    variant="secondary"
                    onClick={() => toggleSetting("addTimestamp")}
                  >
                    Off
                  </OptionButton>
                )}
              </SettingsOption>
            </SettingsItem>
            <SettingsItem>
              <SettingsLabel>Add Heading</SettingsLabel>
              <SettingsOption>
                {geolocation.error || geolocation.heading === null ? (
                  <DisabledLabel>Disabled</DisabledLabel>
                ) : settings.addHeading ? (
                  <OptionButton
                    variant="success"
                    onClick={() => toggleSetting("addHeading")}
                  >
                    On
                  </OptionButton>
                ) : (
                  <OptionButton
                    variant="secondary"
                    onClick={() => toggleSetting("addHeading")}
                  >
                    Off
                  </OptionButton>
                )}
              </SettingsOption>
            </SettingsItem>
            <SettingsItem>
              <SettingsLabel>Auto Submit Photo</SettingsLabel>
              <SettingsOption>
                {settings.autoSubmit ? (
                  <OptionButton
                    variant="success"
                    onClick={() => toggleSetting("autoSubmit")}
                  >
                    On
                  </OptionButton>
                ) : (
                  <OptionButton
                    variant="secondary"
                    onClick={() => toggleSetting("autoSubmit")}
                  >
                    Off
                  </OptionButton>
                )}
              </SettingsOption>
            </SettingsItem>
          </SettingsList>
          {status === STATUS.SUBMITTING && (
            <LoaderOverlay>
              <Loader />
            </LoaderOverlay>
          )}
        </>
      )}
    </PageWrapper>
  );
}

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});

const SettingsList = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  marginTop: 10,
});

const SettingsItem = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const SettingsLabel = styled.p({
  fontSize: 20,
  color: colors.grey,
});

const SettingsOption = styled.div({});

const OptionButton = styled(Button)({
  padding: "8px 20px",
});

const ErrorWrapper = styled.div({
  border: `1px solid ${colors.lightRed4}`,
  borderRadius: 5,
  color: colors.lightRed4,
  padding: "12px 24px",
});

const ErrorTitle = styled.h4({
  fontSize: 24,
  fontWeight: 900,
});

const ErrorBody = styled.p({
  fontSize: 16,
  lineHeight: 1.5,
  marginTop: 8,
});

const ErrorLabel = styled.p({
  color: colors.darkRed,
  backgroundColor: colors.lightRed2,
  border: `1px solid ${colors.lightRed3}`,
  textAlign: "center",
  marginTop: 12,
  marginBottom: 16,
  borderRadius: 5,
  padding: 16,
  "& > a": {
    textDecoration: "underline",
  },
});

const SuccessLabel = styled.p({
  color: colors.darkGreen,
  backgroundColor: colors.lightGreen2,
  border: `1px solid ${colors.lightGreen3}`,
  textAlign: "center",
  marginTop: 12,
  marginBottom: 16,
  borderRadius: 5,
  padding: 16,
  "& > a": {
    textDecoration: "underline",
  },
});

const LoaderOverlay = styled.div({
  position: "absolute",
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 10,
  background: "#ffffffaa",
});

const DisabledLabel = styled.p({
  fontSize: 20,
  padding: "8px 0",
});
