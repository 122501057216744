import "react-phone-number-input/style.css";
import UnstyledPhoneInput from "react-phone-number-input";
import styled from "styled-components";
import { colors } from "../values";

export const PhoneInput = styled(UnstyledPhoneInput)({
  "& > input": {
    background: colors.white,
    padding: 10,
    color: colors.black,
    border: "none",
    outline: "none",
    fontSize: 17,
    lineHeight: "22px",
    width: "100%",
  },
  "& > .PhoneInputCountry": {
    marginRight: 10,
  },
});
