import { useState } from "react";
import styled from "styled-components";
import { colors } from "../values";
import { Button, Divider, Typography } from "./lib";
import { Input } from "./lib/Input";
import { PhoneInput } from "./PhoneInput";

export function RequestForm({ setRequested, ...props }) {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email && !phone) {
      setError("Please enter email address or phone number.");
      return;
    }
    if (email && phone) {
      setError("Please enter either email address or phone number");
      return;
    }
    setError("");
    setRequested(true);
  };

  return (
    <Wrapper {...props}>
      <form onSubmit={handleSubmit}>
        <Typography variant="title" textAlign="left">
          Request Access
        </Typography>
        <EmailInput
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Divider
          text="OR"
          color={colors.white}
          backgroundColor={colors.lightGrey2}
          margin={20}
        />
        <PhoneInput
          defaultCountry="US"
          placeholder="Phone Number"
          value={phone}
          onChange={setPhone}
          style={{
            marginBottom: error ? "0px" : "40px",
          }}
        />
        {error && <ErrorLabel>{error}</ErrorLabel>}
        <RequestButton type="submit">Request Authorization</RequestButton>
      </form>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  padding: "30px 20px",
  borderRadius: 20,
  background: colors.lightGrey2,
});

const EmailInput = styled(Input)({
  marginTop: 20,
});

const RequestButton = styled(Button)({
  marginTop: 10,
  marginBottom: 10,
});

const ErrorLabel = styled(Typography)({
  color: colors.red,
  marginTop: 10,
});
