import styled from "styled-components";
import { BackButton } from "../components/BackButton";
import { Button, Divider, PageWrapper, Typography } from "../components/lib";
import { pages } from "../values";

export function ToolsPage({ handlers, goBack, goToPage }) {
  return (
    <StyledPageWrapper {...handlers}>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">Tools</Typography>
      </Head>
      <Divider marginBottom={10} />
      <Button onClick={() => goToPage(pages.SHUNT_CONVERSION)}>
        Shunt Conversion
      </Button>
      <Button onClick={() => goToPage(pages.METRICS_CONVERSION)}>
        Metrics Conversion
      </Button>
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: 15,
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});
