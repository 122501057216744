export function highlightText(text, searchText) {
  let result = "";

  while (true) {
    let i = text.search(new RegExp(searchText, "i"));
    if (i !== -1) {
      result += text.slice(0, i);
      result += `<mark>${text.slice(i, i + searchText.length)}</mark>`;
      text = text.slice(i + searchText.length);
    } else {
      result += text;
      break;
    }
  }

  return result;
}
