import styled from "styled-components";
import { FaPaperPlane } from "react-icons/fa";
import { BackButton } from "../components/BackButton";
import { FieldMap } from "../components/FieldMap";
import { PageWrapper, Typography } from "../components/lib";
import { useGeolocation } from "../utils";
import { colors } from "../values";
import { useState } from "react";

export function MapPage({ mapAPIKey, goBack }) {
  const geolocation = useGeolocation();
  const [showMarker, setShowMarker] = useState(true);

  const handleLocation = () => {
    setShowMarker((b) => !b);
  };

  return (
    <StyledPageWrapper>
      <Head>
        <BackButton onClick={goBack} />
        <Typography variant="heading">Map</Typography>
      </Head>
      <ContentsWrapper>
        {geolocation.error ||
        (geolocation.latitude === 0 && geolocation.longitude === 0) ? (
          <StyledFieldMap key={1} longitude={-94.9065} latitude={38.9884} />
        ) : (
          <StyledFieldMap
            key={2}
            longitude={geolocation.longitude}
            latitude={geolocation.latitude}
            mapAPIKey={mapAPIKey}
            showMarker={showMarker}
          />
        )}
        <LocationButton onClick={handleLocation}>
          <FaPaperPlane
            size={20}
            color={showMarker ? colors.blue : colors.black}
          />
        </LocationButton>
      </ContentsWrapper>
    </StyledPageWrapper>
  );
}

const StyledPageWrapper = styled(PageWrapper)({
  display: "flex",
  flexDirection: "column",
  paddingInline: 0,
  paddingBottom: 0,
});

const Head = styled.div({
  display: "flex",
  alignItems: "start",
});

const ContentsWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: 15,
  flexGrow: 1,
  height: 0,
  marginTop: 15,
});

const StyledFieldMap = styled(FieldMap)({
  height: "100%",
});

const LocationButton = styled.button({
  background: colors.white,
  borderRadius: 14,
  padding: "14px 20px",
  position: "absolute",
  border: "none",
  right: 12,
  top: 100,
});
